<template>
  <!-- eslint-disable  -->
  <div>
    <SizeBox height="24" />

    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <div class="d-none d-sm-flex justify-end mt-4 mr-4">
        <v-btn
          rounded
          color="primary"
          depressed
          @click="openForm('Info', { mode: 'new' })"
          :disabled="!$getGrantValue('user_list.add')"
        >
          新增
        </v-btn>
      </div>
      <DataTable
        :list="result.list"
        :total="result.total"
        :fields="headers"
        :replace="[
          'status',
          'googleAuthActions',
          'actions',
          'created_at|formatTime'
        ]"
        :loading="isLoading"
        @get-list="fetchData"
      >
        <template #item.status="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.status"
              @click="changeStatus(item)"
              :disabled="item.isStatusChanging"
              class="mr-n4"
              readonly
              inset
            ></v-switch>
          </div>
        </template>

        <template #item.googleAuthActions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.googleAuthActions"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>

        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </DataTable>
    </v-card>

    <InfoForm
      :value="showingForm === 'Info'"
      :passData="passData"
      :roles="roles"
      @close="showingForm = null"
      @save="fetchData"
    />

    <ViewGoogleAuthForm
      :value="showingForm === 'ViewGoogleAuth'"
      @close="showingForm = null"
      :passData="passData"
      @save="fetchData"
    />

    <ResetGoogleAuthForm
      :value="showingForm === 'ResetGoogleAuth'"
      @close="showingForm = null"
      :passData="passData"
      @save="fetchData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    >
      <v-list-item
        v-if="$getGrantValue('user_list.add')"
        @click="openForm('Info', null, 'new')"
      >
        <v-icon>mdi-plus</v-icon>
        <span class="text-no-wrap">新增</span>
      </v-list-item>
    </FunctionFab>
  </div>
</template>

<script>
import { getRoles } from '@/api/roles';
import { getUsers, removeUser, editUserStatus } from '@/api/users';

import InfoForm from '@/components/system-management/managers/InfoForm.vue';
import ViewGoogleAuthForm from '@/components/system-management/managers/ViewGoogleAuthForm.vue';
import ResetGoogleAuthForm from '@/components/system-management/managers/ResetGoogleAuthForm.vue';

const actions = [
  {
    label: '編輯',
    icon: 'mdi-pencil-outline',
    grant: 'user_list.edit',
    action: (vm, v) => {
      vm.openForm('Info', v);
    }
  },
  {
    label: '刪除',
    icon: 'mdi-trash-can-outline',
    grant: 'user_list.remove',
    action: (vm, v) => {
      vm.$confirm({
        title: '刪除',
        content: '刪除之後，資料將無法復原，您確定要刪除？',
        btn: {
          color: 'error',
          text: '刪除'
        },
        callback: async () => {
          await removeUser({ id: v.id });
          vm.$toast('刪除成功');
          vm.fetchData();
        }
      });
    }
  }
];

const googleAuthActions = [
  {
    label: '查看',
    icon: 'mdi-eye-outline',
    grant: 'user_list.google_2fa_secret',
    action: (vm, v) => {
      vm.openForm('ViewGoogleAuth', v);
    }
  },
  {
    label: '重置',
    icon: 'mdi-autorenew',
    grant: 'user_list.google_2fa_secret',
    action: (vm, v) => {
      vm.openForm('ResetGoogleAuth', v);
    }
  }
];

export default {
  components: {
    InfoForm,
    ViewGoogleAuthForm,
    ResetGoogleAuthForm
  },
  async created() {
    const Roles = await getRoles();
    if (!Roles.error) {
      this.roles = Roles.items.map(({ id, name }) => ({
        value: id,
        text: name
      }));
    }

    this.fetchData();
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '管理員 ID',
          value: 'account',
          align: 'center'
        },
        { text: '暱稱', value: 'name', align: 'center' },
        { text: '角色', value: 'role.name', align: 'center' },
        { text: '角色狀態', value: 'status', align: 'center' },
        {
          text: '建立時間',
          value: 'created_at',
          align: 'center'
        },
        {
          text: '谷哥驗證碼',
          value: 'googleAuthActions',
          align: 'center'
        },
        { text: '操作', value: 'actions', align: 'center' }
      ],
      roles: [],
      filterOptions: [
        {
          label: '所屬角色',
          type: 'autocomplete',
          name: 'role_id',
          api: getRoles,
          query: 'roleId'
        },
        {
          label: '使用者',
          type: 'text',
          name: 'query'
        }
      ]
    };
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Users = await getUsers({
        ...this.filter,
        ...condition
      });
      if (!Users.error) {
        this.result.total = Users.total;
        this.result.page = Users.page;
        this.$set(
          this.result,
          'list',
          Users.items.map((item) => ({
            ...item,
            actions,
            googleAuthActions
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    },

    async changeStatus(item) {
      item.isStatusChanging = true;
      const { error } = await editUserStatus({
        id: item.id,
        status: !item.status
      });

      if (error) {
        return;
      } else {
        item.status = !item.status;
      }

      item.isStatusChanging = false;

      return error;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
