import { api } from '.';

export function getUsersByRole(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/users/getUsersByRole', payload);
}

export function addUser(payload) {
  return api('/v1/users/addUser', payload);
}

export function getUser(payload) {
  return api('/v1/users/getUser', payload);
}

export function getUsers(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/users/getUsers', payload);
}

export function editUser(payload) {
  return api('/v1/users/editUser', payload);
}

export function removeUser(payload) {
  return api('/v1/users/removeUser', payload);
}

export function editUserStatus(payload) {
  return api('/v1/users/editUserStatus', payload);
}

export function resetUserPassword({ id, password }) {
  return api('/v1/users/resetUserPassword', { id, password });
}
