export default {
  id: '',
  name: '',
  nickname: '',
  enabled_whitelisted_login_ips: true,
  status: true,
  account: '',
  otp_secret: '',
  whitelisted_ips: [],
  whitelisted_domains: [],
  last_login_at: null,
  created_at: '',
  updated_at: '',
  role: {
    id: '',
    slug: '',
    name: '',
    status: true,
    description: '',
    created_at: '',
    updated_at: ''
  }
};
