<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        {{ localData.mode === 'new' ? '新增' : '編輯' }}
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-window v-model="page">
          <SizeBox height="48" />
          <v-window-item :value="1" class="px-4">
            <v-form ref="form">
              <Async
                :loading="loading"
                :class="loading ? ['w100p', 'flex-center'] : null"
              >
                <v-row>
                  <v-col cols="12">
                    <v-select
                      rounded
                      v-model="roleid"
                      :items="roles"
                      label="角色"
                      placeholder="請選擇"
                      :rules="[$rules.required]"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col>
                    <v-text-field
                      rounded
                      v-model="localData.account"
                      label="管理員帳號"
                      :rules="[$rules.required]"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="localData.mode !== 'new'" cols="auto">
                    <v-btn
                      rounded
                      depressed
                      @click="generatePassword"
                      class="my-3"
                    >
                      重置密碼
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      rounded
                      v-model="localData.name"
                      label="暱稱"
                      :rules="[$rules.required]"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-combobox
                      rounded
                      v-model="localData.whitelisted_ips"
                      label="登入 IP 白名單"
                      small-chips
                      multiple
                      outlined
                    ></v-combobox>
                  </v-col>

                  <v-col cols="auto">
                    <v-switch
                      color="primary"
                      v-model="localData.enabled_whitelisted_login_ips"
                      label="啟用登入 IP 白名單"
                      inset
                    ></v-switch>
                  </v-col>

                  <v-col cols="12">
                    <v-switch
                      label="帳戶狀態"
                      v-model="localData.status"
                      inset
                    ></v-switch>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      rounded
                      v-model="googleVerifyCode"
                      label="谷哥驗證"
                      placeholder="請輸入驗證碼"
                      :rules="[$rules.required]"
                      :error-messages="googleVerifyError"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </Async>
            </v-form>
          </v-window-item>
          <v-window-item :value="2">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  rounded
                  label="預設密碼"
                  :value="generatedPassword"
                  outlined
                  readonly
                >
                  <template #append>
                    <v-btn rounded color="primary" small @click="copy">
                      複製
                    </v-btn>
                  </template>
                </v-text-field>

                <p>溫馨提醒</p>
                <p>請將預設密碼傳送至該人員，完成登入流程</p>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          :disabled="loading"
          @click="close"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          v-if="page === 1"
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          保存
        </v-btn>
        <v-btn
          rounded
          v-if="page === 2"
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="close"
        >
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addUser, editUser, resetUserPassword } from '@/api/users';
import { randomNAString } from '@/plugins/random.js';

import schema from './schema';

export default {
  props: {
    roles: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      localData: {},
      roleid: '',
      page: 1,

      loading: false,
      googleVerifyCode: '',
      googleVerifyError: null,
      generatedPassword: ''
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...schema, ...this.passData });
      this.$refs.form && this.$refs.form.resetValidation();
      this.page = 1;
      this.roleid = this.localData.role.id;
    },
    reset() {
      this.$set(this, 'localData', {});
      this.$refs.form && this.$refs.form.resetValidation();
      this.generatedPassword = '';
      this.page = 1;
    },
    close() {
      this.$emit('close');
    },
    async generatePassword() {
      const { password, error } = await resetUserPassword({
        id: this.passData.id
      });

      if (!error) {
        this.generatedPassword = password;
        this.page = 2;
      } else {
        this.$toast.error('重置失敗');
      }
    },
    copy() {
      window.navigator.clipboard.writeText(this.generatedPassword);
      this.$toast('已複製密碼');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const isNew = this.localData.mode === 'new';
      const firedApi = isNew ? addUser : editUser;
      const passdata = {};
      Object.keys(schema).forEach((key) => {
        passdata[key] = this.localData[key];
      });
      passdata.role_id = this.roleid;
      delete passdata.role;

      if (isNew) {
        delete passdata.id;
        const password = randomNAString(16);
        this.generatedPassword = password;
      }

      if (this.generatedPassword) {
        passdata.password = this.generatedPassword;
      }

      const User = await firedApi(passdata);
      if (!User.error) {
        this.$emit('save');
        this.$toast('保存成功');

        if (isNew || this.generatedPassword) {
          this.page = 2;
        } else {
          this.close();
        }
      } else {
        this.$toast.error('保存失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
